import { Grid, MenuItem, Select, Stack, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { Headline } from "../layout/headline";


const geneNames = ["acp-6","add-2","alr-1","b0238.12","brp-1","c16d9.1","c17g10.10","c25e10.7","c45b2.1","c49f5.7","cnc-10","col-177","col-34","col-69","cpg-22","cutl-8","f19h8.2","f20a1.1","f20a1.10","f35c5.12","f35h10.3","f40h3.2","f54b11.4","f56h9.9","f58f12.4","far-8","files.txt","gad-3","glt-1","irk-1","k08d12.4","lpr-4","mltn-13","ncx-10","nlp-31","nlp-77","r05d8.7","r07b1.8","srd-54","t01e8.8","t21b4.3","t27d12.1","tag-290","ttr-43","ttr-59","tts-1","ugt-45","wrt-6","y47d7a.12","y52e8a.3","y57e12al.4","y67d8c.7.1","y71h10b.1","y7a9d.1","zipt-2.2"];
export const Images = (): JSX.Element => {


  const [geneName, setGeneName] = useState<string>(geneNames[0] as string);



  return <Fragment>
    {geneName && <Fragment>
      <Grid item xs={12} >
        <Headline label="Images" />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" gap={2} alignContent={"center"} justifyContent={"center"} sx={{ my: 6 }}>
          <Typography variant="body1" sx={{ lineHeight: '40px' }}>Glia image of </Typography>
          <Select size="small" value={geneName} onChange={(e) => setGeneName(e.target.value)} sx={{width:'150px'}}>
            {geneNames.map((v: any) => <MenuItem key={v} value={v}>{v}</MenuItem>)}
          </Select>
        </Stack>
      </Grid>
      <Grid item xs={12} sx={{ mb: 12, mx:12 }}>
        <Grid container>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
        <img alt={geneName} src={"/data/images/" + geneName +".jpg"} style={{ width: '100%' }} />
        </Grid>
        </Grid>
      </Grid>
    </Fragment>
    }
  </Fragment>
}