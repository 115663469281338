import { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

interface IProps {
  title: string;
  description: string;
  keywords: string;
  author: string;
}

export default function Head(props: IProps): ReactElement {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta charSet="utf-8" />
      <meta name="description" content={props.description} />
      <meta name="keywords" content={props.keywords} />
      <meta name="author" content={props.author} />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="theme-color" content="#000000" />
    </Helmet>
  );
}