import { Router } from './app/Router';
import { Header } from './app/components/Header';


function App() {

  return (
    <>
      <Header title="" />
      <Router />
    </>
  );
}

export default App;
