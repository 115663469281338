import { AppBar, Box, Toolbar, Typography } from "@mui/material";
type Props = {
  title: string;
};

export const Header = ({ title }: Props): JSX.Element => {
  return (<Box>
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Worm Glia Molecular Atlas
          </Typography>
  
        </Toolbar>
      </AppBar>
    </Box>);
  
}

