import { Box, Grid, MenuItem, Select, Stack, Typography } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import Plot from "react-plotly.js";
import {
  Vitessce
} from 'vitessce'
import { Images } from "../components/images/Images";
import { Headline } from "../components/layout/headline";
import { ViewAll } from "./ViewAll";
import { ViewGlia } from "./ViewGlia";


export const PageLanding = (): ReactElement => {

  const [cellTypes] = useState<Array<string>>(['Glia', 'All']);
  const [cellType, setCellType] = useState<string>('Glia');
  
  const [clusterNumbers] = useState<Array<number>>(Array.from(Array(32).keys()));
  const [clusterNumber, setClusterNumber] = useState<number>(0);
  const [clusterData, setClusterData] = useState<Array<any> | null>(null);

  useEffect(() => {
    (async () => {
      const data = await (await fetch(`/data/glia_pairwise/${clusterNumber}_GliaPairwise.json`)).json();
      delete data[1].title
      setClusterData(data);
    })();
  }, [clusterNumber]);

  
  return <Box sx={{ mt: 8 }}>
    <Grid container>
      <Grid item xs={8} sx={{ p: 8 }} >
        <Typography variant="h2" sx={{ pb: 2 }}>The complete atlas of adult C.elegans glia across sexes</Typography>
        <Typography variant="body1"  >
          A comprehensive mechanistic understanding of nervous system function requires molecular insight into the diversity and sex dimorphism of both its component cell types: glia and neurons. Here, we present a single nuclear RNA-seq atlas of all glia of the adult C. elegans nervous system, across both sexes. Through computational modeling and in vivo validation, we identify molecular markers that separate anatomically distinct glia, and molecular heterogeneity within anatomically identical glia, which together imply extensive functional heterogeneity. We report that different glia are variably sexually dimorphic. And, we also define glial subclass and pan-glial molecular signatures. Finally, this global profiling uncovers that adult C. elegans glia do not use canonical dense core vesicle release machinery to release glial neuropeptides. Thus, complementing the existing single-cell neuron atlas and mapped connectome in C. elegans, this atlas (wormglia.org) now enables a complete and unprecedented single-cell and molecular insight of glia, and thereby, the nervous system of an adult metazoan.
        </Typography>
      </Grid>
      <Grid item xs={4} sx={{ p: 8 }}>
        <Typography variant="h2" sx={{ pb: 2 }}>Authors</Typography>
        Maria D. Purice1, Elgene J.A. Quitevis1, 2, R. Sean Manning1, Liza J. Severs1, Nina-Tuyen Tran1, Violet Sorrentino1, Manu Setty1, 2*, Aakanksha Singhvi1, 3,*
        <Typography variant="h2" sx={{ pb: 2, pt: 4 }}>Affiliations</Typography>
        Fred Hutchinson Cancer Center<br />
        University of Washington School of Medicine
      </Grid>
      <Grid item xs={12} >
        <Headline label="Cell Population" />
      </Grid>
      <Grid item xs={12}  sx={{ textAlign: "center" }}>
      <Stack direction="row" gap={2} alignContent={"center"} justifyContent={"center"} sx={{ my: 6 }}>
      <Typography variant="body1" sx={{ lineHeight: '40px' }}>Cell Population </Typography>
        <Select size="small" value={cellType} onChange={(e) => setCellType(e.target.value as string)} sx={{width:'150px'}} >
          {cellTypes.map(v => <MenuItem key={`cell_${v}`} value={v}>{v}</MenuItem>)}
        </Select>
        </Stack>
      </Grid>
      {(cellType === 'All') && <Grid item xs={12}  sx={{ textAlign: "center", height: '800px' }}>
        <Vitessce config={ViewAll} height={800}/>
      </Grid>}
      {(cellType === 'Glia') && <>
      <Grid item xs={12}  sx={{ textAlign: "center", height: '800px', pb:0}}>
        <Vitessce config={ViewGlia} height={800} />
      </Grid>
      <Grid item xs={12} >
        <Headline label={`Pairwise Analysis Results `} />
      </Grid>
      <Grid item xs={12}  sx={{ textAlign: "center" }}>
      <Stack direction="row" gap={2} alignContent={"center"} justifyContent={"center"} sx={{ my: 6 }}>
      <Typography variant="body1" sx={{ lineHeight: '40px' }}>Cluster Specific Genes </Typography>
        <Select size="small" value={clusterNumber} onChange={(e) => setClusterNumber(e.target.value as number)} sx={{width:'150px'}}>
          {clusterNumbers.map(v => <MenuItem key={`cluster_${v}`} value={v}>Cluster {(v).toString()}</MenuItem>)}
        </Select>
        </Stack>
      </Grid>
      <Grid item xs={12}  sx={{  textAlign: "center", pb:6 }}>
        {clusterData && <Plot data={clusterData[0]} layout={clusterData[1]} />}
      </Grid>
      <Grid item xs={12}  sx={{  textAlign: "center" }}>
        <Images />
      </Grid>
      </>}
    </Grid>
  </Box>
}
