import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import Head from './app/components/Head';
import { theme } from './app/Theme';
import reportWebVitals from './reportWebVitals';
import './assets/main.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HelmetProvider>
        <Head title="Worm Glia" description="A comprehensive mechanistic understanding of nervous system function requires molecular insight into the diversity and sex dimorphism of both its component cell types: glia and neurons. Here, we present a single nuclear RNA-seq atlas of all glia of the adult C. elegans nervous system, across both sexes. Through computational modeling and in vivo validation, we identify molecular markers that separate anatomically distinct glia, and molecular heterogeneity within anatomically identical glia, which together imply extensive functional heterogeneity. We report that different glia are variably sexually dimorphic. And, we also define glial subclass and pan-glial molecular signatures. Finally, this global profiling uncovers that adult C. elegans glia do not use canonical dense core vesicle release machinery to release glial neuropeptides. Thus, complementing the existing single-cell neuron atlas and mapped connectome in C. elegans, this atlas (wormglia.org) now enables a complete and unprecedented single-cell and molecular insight of glia, and thereby, the nervous system of an adult metazoan." keywords="single cell, worm, glia" author="Manu Setty" />
        <App />
      </HelmetProvider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
