import { ReactElement } from 'react';
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';

import { PageLanding } from './page/Landing';


const router = createBrowserRouter([
  {
    path: '/',
    element: <PageLanding />,
  },
] as RouteObject[]);

export function Router(): ReactElement {
  return (
    <RouterProvider router={router} />
  );
}
