import { createTheme, ThemeOptions } from '@mui/material/styles';

export const COLOR_BRAND_DARK = "#070b25";
export const COLOR_BRAND_MEDIUM = "#397b9d";
export const COLOR_BRAND_LIGHT = "#5cbcd1";
export const COLOR_BG_DARK = "#000";
export const COLOR_BG_MEDIUM = "#F4F4F7";
export const COLOR_BG_LIGHT = "#fff";
export const COLOR_TEXT_DARK = COLOR_BRAND_DARK;
export const COLOR_TEXT_MEDIUM = COLOR_BG_MEDIUM;
export const COLOR_TEXT_LIGHT = COLOR_BG_LIGHT

export const theme: ThemeOptions = createTheme(
  {
    // shadows: ["none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none", "none"],
    palette: {
      background: {
        default: COLOR_BG_LIGHT,
        paper: COLOR_BG_LIGHT
      },
      primary: {
        main: COLOR_BRAND_DARK,
      },
      text: {
        primary: COLOR_TEXT_DARK
      }
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
      fontSize: 14,
      h1: {
        fontSize: '2rem',
      },
      h2: {
        fontSize: '1.5rem',
      }
    },
    components: {},
  });